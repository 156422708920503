import React from "react";
import { Link } from "react-router-dom";

export default function Home () {
  return (
<div id="container" class="bg">
		<div id="content">
			<section class="slider">
				<div class="swiper">
					<ul class="swiper-wrapper lst">
						<li class="swiper-slide"><a href="#!"><img src="/xpass/pjtCom/images/mbanner1.jpg" alt="" /></a></li>
						<li class="swiper-slide"><a href="#!"><img src="/xpass/pjtCom/images/mbanner2.jpg" alt="" /></a></li>
						<li class="swiper-slide"><a href="#!"><img src="/xpass/pjtCom/images/mbanner3.jpg" alt="" /></a></li>
						<li class="swiper-slide"><a href="#!"><img src="/xpass/pjtCom/images/nft6.jpg" alt="" /></a></li>
					</ul>
					<div class="swiper-pagination"></div>
				</div>
			</section>
			<div class="area9">
				<div class="bg1"><img src="/xpass/pjtCom/images/xpattern1.png" alt="" /></div>
			</div>
			<section class="area">
				<div class="tle"><h2><span>X</span> TICKET 예매</h2></div>
				<div class="swiper1">
					<ul class="swiper-wrapper lst1">
						<li class="swiper-slide"><a href="#!"><div><img src="/xpass/pjtCom/images/res1.jpg" alt="" /></div><p><span>아메리칸 팝아트 거장전 2024</span></p></a></li>
						<li class="swiper-slide"><a href="#!"><div><img src="/xpass/pjtCom/images/res2.jpg" alt="" /></div><p><span>뮤지컬 (알라딘)</span></p></a></li>
						<li class="swiper-slide"><a href="#!"><div><img src="/xpass/pjtCom/images/res3.jpg" alt="" /></div><p><span>뮤지컬 에반 한센</span></p></a></li>
						<li class="swiper-slide"><a href="#!"><div><img src="/xpass/pjtCom/images/nft5.jpg" alt="" /></div><p><span>뮤지컬 에반 한센</span></p></a></li>
					</ul>
				</div>
				<div class="area1">
					<a class="btn_m" href="#!">예매 더 보기 +</a>
				</div>
			</section>
			<section class="area2">
				<div class="tle"><h2><span>티</span>켓 NFT</h2></div>
				<div class="area4">
					<ul class="lst2">
						<li>
							<a href="#!">
								<div class="box">
									<img src="/xpass/pjtCom/images/nft1.jpg" alt="" />
									<div class="btn_area">
										<button class="btn_ut2 active"><span class="sp1">like</span><span>237</span></button>
									</div>
								</div>
								<div class="box1">
									<p class="txt">뮤지컬 오페라의 유령</p>
									<p>라움아트센터 (마제스틱 볼룸)</p>
									<p>2024.05.08 (수) 19:30</p>
									<p>S석 2층 B구역 3열</p>
									<p class="txt txt_1"><span>400</span> KLAY</p>
									<p class="txt1">(≈ <span>120,000</span>원)</p>
								</div>
							</a>
							
						</li>
						<li>
							<a href="#!">
								<div class="box">
									<img src="/xpass/pjtCom/images/nft2.jpg" alt="" />
									<div class="btn_area">
										<button class="btn_ut2"><span class="sp1">like</span><span>37</span></button>
									</div>
								</div>
								<div class="box1">
									<p class="txt">뮤지컬 (알라딘) 한국어라이선스공연 2024</p>
									<p>라움아트센터 (마제스틱 볼룸)</p>
									<p>2024.05.08 (수) 19:30</p>
									<p>S석 2층 B구역 3열</p>
									<p class="txt txt_1"><span>400</span> KLAY</p>
									<p class="txt1">(≈ <span>1120,000</span>원)</p>
								</div>
							</a>
						</li>
						<li>
							<a href="#!">
								<div class="box">
									<img src="/xpass/pjtCom/images/nft3.jpg" alt="" />
									<div class="btn_area">
										<button class="btn_ut2 active"><span class="sp1">like</span><span>3</span></button>
									</div>
								</div>
								<div class="box1">
									<p class="txt">뮤지컬 오페라의 유령</p>
									<p>라움아트센터 (마제스틱 볼룸)</p>
									<p>2024.05.08 (수) 19:30</p>
									<p>S석 2층 B구역 3열</p>
									<p class="txt txt_1"><span>400</span> KLAY</p>
									<p class="txt1">(≈ <span>1120,000</span>원)</p>
								</div>
							</a>
						</li>
						<li>
							<a href="#!">
								<div class="box">
									<img src="/xpass/pjtCom/images/nft4.jpg" alt="" />
									<div class="btn_area">
										<button class="btn_ut2"><span class="sp1">like</span><span>37</span></button>
									</div>
								</div>
								<div class="box1">
									<p class="txt">제16회 서울재즈페스티벌 2024</p>
									<p>라움아트센터 (마제스틱 볼룸)</p>
									<p>2024.05.08 (수) 19:30</p>
									<p>S석 2층 B구역 3열</p>
									<p class="txt txt_1"><span>400</span> KLAY</p>
									<p class="txt1">(≈ <span>1120,000</span>원)</p>
								</div>
							</a>
						</li>
					</ul>
				</div>
				<div class="area1">
					<a class="btn_m" href="#!">티켓 더 보기 +</a>
				</div>
			</section>
			<section class="area">
				<div class="tle"><h2><span>굿</span>즈 NFT</h2></div>
				<div class="area4">
					<ul class="lst3">
						<li>
							<a href="#!">
								<div class="box">
									<img src="/xpass/pjtCom/images/nft1.jpg" alt="" />
									<div class="btn_area">
										<button class="btn_ut2 active"><span class="sp1">like</span><span>37</span></button>
									</div>
								</div>
								<div>
									<p class="txt">뮤지컬 오페라의 유령 #1234</p>
									<p class="txt txt_1"><span>400</span> KLAY</p>
									<p class="txt1">(≈ <span>120,000</span>원)</p>
								</div>
							</a>
						</li>
						<li>
							<a href="#!">
								<div class="box">
									<img src="/xpass/pjtCom/images/nft2.jpg" alt="" />
									<div class="btn_area">
										<button class="btn_ut2"><span class="sp1">like</span><span>374</span></button>
									</div>
								</div>
								<div>
									<p class="txt">SCHOOL OF ROCK</p>
									<p class="txt txt_1"><span>400</span> KLAY</p>
									<p class="txt1">(≈ <span>120,000</span>원)</p>
								</div>
							</a>
						</li>
						<li>
							<a href="#!">
								<div class="box">
									<img src="/xpass/pjtCom/images/nft3.jpg" alt="" />
									<div class="btn_area">
										<button class="btn_ut2 active"><span class="sp1">like</span><span>1</span></button>
									</div>
								</div>
								<div>
									<p class="txt">NEXT TO NORMAL</p>
									<p class="txt txt_1"><span>400</span> KLAY</p>
									<p class="txt1">(≈ <span>120,000</span>원)</p>
								</div>
							</a>
						</li>
						<li>
							<a href="#!">
								<div class="box">
									<img src="/xpass/pjtCom/images/nft4.jpg" alt="" />
									<div class="btn_area">
										<button class="btn_ut2"><span class="sp1">like</span><span>37</span></button>
									</div>
								</div>
								<div>
									<p class="txt">이은결 MAGIC & ILLUSION 2024 #1234</p>
									<p class="txt txt_1"><span>400</span> KLAY</p>
									<p class="txt1">(≈ <span>120,000</span>원)</p>
								</div>
							</a>
						</li>						
					</ul>
				</div>
				<div class="area1">					
					<a class="btn_m" href="#!">굿즈 더 보기 +</a>
				</div>
			</section>
      <section class="area3">
	<div>
		<ul class="lst4">
			<li><Link to="/" class="home">HOME</Link></li>
			<li><Link to="/ticket" class="tick">TICKET</Link></li>
			<li><Link to="/market" class="mark">MARKET</Link></li>
			<li><Link to="/mypage" class="mypa">MY PAGE</Link></li>
		</ul>
	</div>
</section>
</div>
</div>
  )
} 

