import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from '../routes/Login';
import MyPage from '../routes/MyPage';
import MyPageNFT from "../routes/MyPageNFT.js"
import MyPageNFTDetail from '../routes/MyPageNFTDetail.js';
import Home from "../routes/Home";
import Ticket from "../routes/Ticket";
import Market from "../routes/Market"; // NFT 마켓
import Header from "./Header";
import MarketDetail from "../routes/MarketDetail.js";
//import HotMenu from "./HotMenu";
//import $ from 'jquery';

const AppRouter = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/mypage" element={<MyPage/>} />
        <Route path="/mypage_nft" element={<MyPageNFT/>} />
        <Route path="/mypage_nft_detail/:tid" element={<MyPageNFTDetail/>} />
        <Route path="/ticket" element={<Ticket/>} />
        <Route path="/market" element={<Market/>} />
        <Route path="/market_detail/:tid" element={<MarketDetail/>} />
      </Routes>
    </Router>
  );
};

export default AppRouter;