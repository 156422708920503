import React, { useState, useEffect } from "react";
import { getNftInfo, getSaleStatus, getSellPrice } from "../api/UseCaver";
import * as KlipAPI from "../api/UseKlip";
import { DEFAULT_QR_CODE, DEFAULT_ADDRESS } from "../constants/constants.klip";
import { useParams, useNavigate } from 'react-router-dom';
import QRcode from "../components/QRcode";

export default function MyPageNFTDetail () {
  const { tid } = useParams();
  const navigate = useNavigate();

  //const [nfts, setNfts] = useState([]);
  const myAddress = (window.sessionStorage.getItem('address') || DEFAULT_ADDRESS);
	//const myBalance = (window.sessionStorage.getItem('balance') || '0');

	const [qrvalue, setQrvalue] = useState(DEFAULT_QR_CODE);

  // fetch data array
  //let nfts_data = [];

  //공연중일 경우 판매가격에 제한을 주기위한 체크함수
	//const [checkLitmitPrice, setCheckLitmitPrice] = useState(false);
	let checkBeforeDate = false;

  // 공연중인 티켓일 경우 2차 판매금액의 최고금액을 제한한다.
const getCheckBeforeDate = (_pDate) => {
  //const regex = /[^0-9]/g;
  _pDate = _pDate.replace("년", "-");
  _pDate = _pDate.replace("월", "-");
  _pDate = _pDate.replace("일", "");
  _pDate = _pDate.replace(/\s+/g, "");
  const arrDate = _pDate.split("-");

  const pDate = new Date(`${arrDate[0]}-${arrDate[1] >= 10 ? arrDate[1] : '0' + arrDate[1]}-${arrDate[2] >= 10 ? arrDate[2] : '0' + arrDate[2]}`);
  //console.log(pDate);
  const tDate = new Date();
  if(pDate.getTime() > tDate.getTime()) {
  
    return true;
  }
  return false;
}
//종가 클레이 가격
const [klayPrice, setKlayPrice] = useState('0');	
//판매승인 확인
const [saleStatus, setSaleStatus] = useState(false);
//const account = myAddress;
//판매가격
const [sellPrice, setSellPrice] = useState("0");
const [inputSellPrice, setInputSellPrice] = useState("");
//최초판매가격
const [ticketPrice, setTicketPrice] = useState("0");
//fetch data
const [imageUri, setImageUri] = useState("");
const [playDate, setPlayDate] = useState("");
const [playName, setPlayName] = useState("");
/////////////////////////////////////////////////////////////////////
//판매승인 토글
const onClickApproveMyCard = () => {
  const _saleStatus = !saleStatus;
  KlipAPI.approveSaleCardAll(_saleStatus, setQrvalue, (result) => {
    alert(JSON.stringify(result));
    window.location.reload();
  });
};

const onClickApproveCard = (_msg) => {
//setModalProps({
 // title: _msg,
 // onConfirm: () => {
    onClickApproveMyCard();
  //},
//})
//setShowModal(true);
}
//판매등록 이벤트
const onClickSellPriceExe = (tokenId, _sellPrice) => {
  const sellPrice = (_sellPrice * 1000000000000000000).toString(); //to pep price : 1klay = 10 ^ 18;
  KlipAPI.setForSaleXPassTocken(tokenId, sellPrice, setQrvalue, (result) => {
    alert(JSON.stringify(result));
    window.location.reload();
  });
};
const onClickSellPrice = () => {
  //판매승인 확인
  if(!saleStatus){
    alert('판매전 판매승인을 먼저 하셔야 합니다.');
    return;
  }
  //공연중 일때 가격제한 
  if(inputSellPrice === "" || inputSellPrice <= 0){
    alert('판매가격을 입력해 주세요');
    return;
  }
  
  checkBeforeDate = getCheckBeforeDate(playDate);
  if(checkBeforeDate && inputSellPrice > 0){
    const limitPrice = ticketPrice * 2;
    const klayToWon = klayPrice * inputSellPrice;
    //alert('공연전?:' + checkBeforeDate + ' / 제한금액 : ' + limitPrice + ' / 원화변환 : ' + klayToWon);
    //return;
    
    if(klayToWon > limitPrice) {
      alert('공연중인 티켓은 ' + limitPrice + ' 를 초과할 수 없습니다.');
      return;
    }

  }
  //alert(inputSellPrice + " klay에 판매하시겠습니까?");
  //setModalProps({
  //  title: inputSellPrice + " klay에 판매하시겠습니까?",
  //  onConfirm: () => {
       onClickSellPriceExe(tid, inputSellPrice);
 //   },
  //})
  //setShowModal(true);

}
const onChangeSellPrice = (event) => {
  setInputSellPrice(event.target.value);
}
/*
const getUserData = () => {
  if(myAddress !== DEFAULT_ADDRESS) {
    window.location.replace('/mypage');
    //const navigate = useNavigate()
    //navigate('/mypage', {replace: true})  
    return;
  }
  KlipAPI.getAddress(setQrvalue, async (address) => {
    window.sessionStorage.setItem('address', address);
    setMyAddress(address);
    console.log(myAddress);
    console.log(qrvalue);
    const _balance = await getBalance(address);
    window.sessionStorage.setItem('balance', _balance);
    setMyBalance(_balance);
    console.log(myBalance);
    //const _balance_xtc = await getBalanceXTC(address);
    //window.sessionStorage.setItem('xtcbalance', _balance_xtc);
    //setXtcBalance(_balance_xtc);
    //console.log(xtcBalance);
    //navigate('/goods');
    //window.location.replace('/mypage');
    return;
  })
}
*/
////////////////////////////////////////////////////////////////////////////////
useEffect(() => {

  if(myAddress === DEFAULT_ADDRESS) {
    window.location.replace('/login');
    return;
  }

  // fetch data and set data
  //nfts.push({ uri: tokenUris[i], id: tokenIds[i], name: tokenName[i], description: tokenDesc[i], attributes: tokenAttr[i], sellPrice: tokenPrice[i] });
  const setNftMetaData = async (_nfts) =>{
    _nfts.map((nft) => {
      setImageUri(nft.uri);
      setPlayName(nft.name);

      nft.attributes.map((attr) => {
        //공연일 이전인지 체크
        if(attr.trait_type === "공연일시"){
          //console.log(attr.value);
          //checkBeforeDate = getCheckBeforeDate(attr.value);
          setPlayDate(attr.value);
          //attr.value = new Date();
        }
        //공연중일때 판매가격 제한
        if(attr.trait_type === "티켓가격"){
          //attr.value = Number(attr.value) * 2;
          setTicketPrice(attr.value);
          //klay 현재가격
  
          //최대가격이상일 경우 리턴
  
          
        }
        
      })
    })
  
}
  const fetchMyNFTs = async () => {
    if (myAddress === DEFAULT_ADDRESS) {
      alert("NO ADDRESS");
      return;
    }
    const _nfts = await getNftInfo(tid);
      //setNfts(_nfts);
      setNftMetaData(_nfts);
      //console.log(_nfts);
      //nfts_data.push({ uri: nfts[0].uri, id: nfts[0].id, name: nfts[0].name, description: nfts[0].description, attributes: nfts[0].attributes, sellPrice: nfts[0].sellPrice });

    const _saleStatus = await getSaleStatus(myAddress);
    setSaleStatus(_saleStatus);
  }

  //종가 클레이튼 가격 가져요기
  const fetchKlayPrice = async () => {
    if (myAddress === DEFAULT_ADDRESS) {
      alert("NO ADDRESS");
      return;
    }

    try {
      const URL = "https://gametok.co.kr/api/klay_info.json";

      const response = await fetch(URL);
      const result = await response.json();
      console.log(result.tickers[0].last);
      setKlayPrice(result.tickers[0].last);
    }catch(error){
      console.error(error);
    }
    
    //setKlayPrice('258');
  }

  //판매가격 가져오기
  const getSellPriceByTid = async () => {
    if (myAddress === DEFAULT_ADDRESS) {
      alert("NO ADDRESS");
      return;
    }

    try {
      const _sellPrice = await getSellPrice(tid);
      console.log(_sellPrice);
      setSellPrice(_sellPrice);

    } catch(error) {
      console.log(error);
    }
  }

  if (myAddress !== DEFAULT_ADDRESS) {
    fetchMyNFTs();
    fetchKlayPrice();
    getSellPriceByTid();
  }

  
}, [myAddress, tid]);

//////////////////////////////////////////////////////////////////////////////////
/*
nfts.map((nft) => {
    setImageUri(nft.uri);
    setPlayName(nft.name);
    nft.attributes.map((attr) => {
      //공연일 이전인지 체크
      if(attr.trait_type === "공연일시"){
        //console.log(attr.value);
        checkBeforeDate = getCheckBeforeDate(attr.value);
        setPlayDate(attr.value);
        //attr.value = new Date();
      }
      //공연중일때 판매가격 제한
      if(attr.trait_type === "티켓가격"){
        //attr.value = Number(attr.value) * 2;
        setTicketPrice(attr.value);
        //klay 현재가격

        //최대가격이상일 경우 리턴

        
      }
      
    })

})
 */   
  return (
    <React.Fragment>
<div id="container">
		<div id="content">
			<section class="area7 v2">
				<ul class="lst11">
					<li><a class="btn_ut" href="#!" onClick={() => navigate(-1)}>back</a></li>
					<li><h2>상세정보</h2></li>
					<li><button class="btn_ut5 modal_open">Share</button></li>
				</ul>
			</section>
      
			<section class="area11">
				<div class="box4">
					<div class="imgbox"><img src={imageUri} alt="" /></div>
					<div class="btn_area v2">
						<button class="btn_ut2 v2"><span class="sp1">like</span><span>37</span></button>
					</div>
				</div>
				<div class="area12">
					<div class="tle2"><h3>{playName}</h3></div>
					<ul class="lst12">
						<li><p class="txt">by <span>엑스티켓</span></p></li>
	
						<li><p class="index"><span>T</span>ICKET</p></li>						
	
					</ul>
					<ul class="lst13">
						<li><p>판매가</p></li>
						<li>
            
          {sellPrice === "0" ? (
              <div>
                <input type='number' onChange={onChangeSellPrice} value={inputSellPrice}></input>  KLAY
              </div>
              
            ) : (
              <div>
                <p class="txt"><span>{sellPrice}</span> KLAY</p>
							  <p class="txt2">(≈ <span>{klayPrice * sellPrice}</span>원)</p>
              </div>
              
            )}
						</li>						
					</ul>
				</div>
			</section>
      	
			<section> 
				<div class="area10 taparea" now="1">
					<div>
						<ul class="lst7">
							<li><button class="tap active" tap="1">거래 정보</button></li>
							<li><button class="tap" tap="2">작품 정보</button></li>
						</ul>
					</div>
					<ul class="lst9">
						<li>
							<ul class="lst14">
								<li class="li1"><div class="imgbox"><img src="/xpass/pjtCom/images/nft6.jpg" alt="" /></div></li>
								<li class="li2">
									<p>보유자</p>
									<p class="txt1"><a href="#!">{myAddress}</a></p>
								</li>
							</ul>
			
							<div class="area13">
								<ul class="box6">
									<li class="top"></li>
									<li class="bottom"></li>
									<li class="left"></li>
									<li class="right"></li>
									<li class="lt"></li>
									<li class="rt"></li>
									<li class="rb"></li>
									<li class="lb"></li>
								</ul>
								<div class="box5">
									<div><h4 class="tle3"><span>티</span>켓정보</h4></div>
									<ul class="lst15">
										<li><span>공연장소</span><span>충무아트센터 대극장</span></li>
										<li><span>관람일시</span><span>{playDate}</span></li>
										<li><span>좌석등급</span><span>VIP석</span></li>
										<li><span>좌석정보</span><span>1층 B구역 2열</span></li>
										<li><span>티켓 정가</span><span>{ticketPrice} 원</span></li>
									</ul>
								</div>
								<div class="area14"><p class="txt3">*티켓 사용방법은 공지사항을 참고해주세요.</p></div>
							</div>
	
							<div class="area15">
								<div class="area16">
									<div class="tle_area"><h4 class="tle3"><span>거</span>래 히스토리</h4></div>
									<div class="area17">
										<ul class="lst16 ">
											<li><span>23 KLAY</span><span>2024.02.08 17:24:07</span><span><a href="#!">0xa9C9</a></span><span>구매</span></li>
											<li><span>23 KLAY</span><span>2024.02.08 17:24:07</span><span><a href="#!">0xa9C9</a></span><span>구매</span></li>
											<li><span>23 KLAY</span><span>2024.02.08 17:24:07</span><span><a href="#!">0xa9C9</a></span><span>구매</span></li>
											<li><span>23 KLAY</span><span>2024.02.08 17:24:07</span><span><a href="#!">0xa9C9</a></span><span>구매</span></li>
										</ul>
									</div>
								</div>
                {qrvalue !== "DEFAULT" ? <QRcode value={qrvalue} /> : null}
								<div class="area16">
									<div class="tle_area"><h4 class="tle3"><span>구</span>매 가이드</h4></div>
									<div class="area18">
										<ul class="lst17">
											<li>구매는 연동된 지갑 내 클레이튼(KLAY)이 차감되는 방식으로 진행됩니다.</li>
											<li>구매 거래가 체결되면 거래 취소가 불가하므로 신중하게 결정하여 진행해주세요.</li>
											<li>해당 NFT에 연계된 디지털상품 관련 분쟁 (지식재산권 분쟁 포함)이 발생한 경우 해당 NFT에 대한 거래지원이 종료될 수 있습니다.</li>
											<li>NFT 보유자는 NFT와 연계된 “디지털 저작물“을 비상업적인 용도로 사용(개인 SNS 내 업로드하는 행위 및 디지털 저작물 원본 그대로 개인적 용도로 사용하는 행위)할 수 있는 권리와 NFT 입출금, 거래 등이 지원되는 경우 지원되는 방식에 따라 NFT를 타인에게 이전할 수 있는 권리를 갖습니다.</li>
											<li>NFT 판매등록 상태에서, 이용약관에 따른 이용제한 또는 서비스 중단의 경우 해당 판매등록 및 가격제안이 표시되지 않으며, 위 사유가 해소되는 경우 해당 판매등록 및 가격제안이 표시됩니다.</li>	
										</ul>																	
									</div>
								</div>
							</div>
						</li>
						<li>
							<div class="area15">
								<div class="area16">
									<div class="tle_area"><h4 class="tle3"><span>컬</span>렉션 정보</h4></div>
									<div class="area17">
										<p class="txt4"></p>
									</div>																
								</div>
								<div class="area16">
									<div class="tle_area"><h4 class="tle3"><span>발</span>행 정보</h4></div>
									<ul class="lst18 area17">
										<li><span>발행일</span><span>2024.01.24</span></li>
										<li><span>작품번호</span><span>2100210003</span></li>
									</ul>
								</div>
								<div class="area16">
									<div class="tle_area"><h4 class="tle3"><span>N</span>FT 정보</h4></div>
									<ul class="lst18 area17">
										<li><span>Contract Address</span><span><a href="#!">0x3ccd...3a58</a></span></li>
										<li><span>Token ID</span><span><a href="#!">4784</a></span></li>
										<li><span>Token Standard</span><span>ERC-1155</span></li>
										<li><span>Chain</span><span>Base</span></li>
										<li><span>Last Updated</span><span>5 hours ago</span></li>
										<li><span>Creator Earnings</span><span>5%</span></li>
									</ul>
								</div>
							</div>
						</li>
					</ul>	
				</div>
			</section>

			<section class="area19">
				<div class="area20">
					<ul class="btn_area3">
          
          {saleStatus ? (
						<li><a class="btn v3" href="#!" onClick={() => {onClickApproveCard()}}>판매승인취소</a></li>		
          ):
          <li><a class="btn v3" href="#!" onClick={() => {onClickApproveCard()}}>판매승인</a></li>
          }	
          {sellPrice > 0 ? (
          <li><a class="btn" href="#!">판매중</a></li>
          ):
          <li><a class="btn" href="#!" onClick={onClickSellPrice}>판매하기</a></li>
          }			
						

					</ul>
				</div>
			</section>

		</div>
	</div>

	<div class="modal_bg modal_close">
	</div>

	<div class="modal_box">
		<div class="modal modal1 share1">
			<ul class="lst10 v2">
				<li><button class="copy"><span>URL 복사하기</span></button></li>
				<li><button class="cacao"><span>카카오톡에 공유하기</span></button></li>
				<li><button class="ex"><span>엑스에 공유하기</span></button></li>

			</ul>
			<div class="btn_area2"><button class="btn_ut4 modal_close">Cancel</button></div>
		</div>
	</div>
  
    </React.Fragment>
    
  )
  
}
