import React, { useState, useEffect } from "react";
import { fetchCardsOfSale } from "../api/UseCaver";
import { Link } from "react-router-dom";

export default function Market () {
    const [nfts, setNfts] = useState([]);

     // NFT 총보유수(공연전티켓), 보유/판매중, GOODS 총보유수(공연후티켓), 보유/판매중
    const [nftCnt, setNftCnt] = useState('0');

    useEffect(() => {
		const fetchMyNFTs = async () => {

			const _nfts = await fetchCardsOfSale();
				setNfts(_nfts);
                setNftCnt(_nfts.length);
		
		}
/*
		//종가 클레이튼 가격 가져요기
		const fetchKlayPrice = async () => {
			//if (myAddress === DEFAULT_ADDRESS) {
				//alert("NO ADDRESS");
				//return;
			//}

			try {
				const URL = "https://gametok.co.kr/api/klay_info.json";

				const response = await fetch(URL);
      	const result = await response.json();
				console.log(result.tickers[0].last);
				setKlayPrice(result.tickers[0].last);
			}catch(error){
				console.error(error);
			}
			
			//setKlayPrice('258');
		}
*/
		//if (myAddress !== DEFAULT_ADDRESS) {
			fetchMyNFTs();
			//fetchKlayPrice();
		//}
		
	}, []);

    return (
    <React.Fragment>
        <div id="container">
		<div id="content">
			<section class="area8 area8_1">
				<div class="tle"><h2><span>마</span>켓</h2></div>
				<div class="btn_area1"><button class="btn_ut1">Search</button></div>
				<div class="area10 taparea" now="1">
					<div>
						<ul class="lst7">
							<li><button class="tap active" tap="1">티켓 NFT <span>(<span>{nftCnt}</span>)</span></button></li>
							<li><button class="tap" tap="2">굿즈 NFT <span>(<span>4</span>)</span></button></li>
						</ul>
					</div>
					<ul class="lst9">
						<li>
							<ul class="lst8">
								<li><p>총 <span>{nftCnt}</span> 건</p></li>
								<li><button class="btn_ut3 modal_open1"><span class="sorting1">최신순</span></button></li>
							</ul>
							<ul class="lst2 lst2_1">
                            {nfts.map((nft) => (
								<li>
									<Link to={`/market_detail/${nft.id}`}>
										<div class="box">
											<img src={nft.uri} alt="" />
											<div class="btn_area">
												<button class="btn_ut2 active"><span class="sp1">like</span><span>237</span></button>
											</div>
										</div>
										<div class="box1">
											<p class="txt">뮤지컬 오페라의 유령</p>
											<p>라움아트센터 (마제스틱 볼룸)</p>
											<p>2024.05.08 (수) 19:30</p>
											<p>S석 2층 B구역 3열</p>
											<p class="txt txt_1"><span>{nft.sellPrice}</span> KLAY</p>
											<p class="txt1">(≈ <span>120,000</span>원)</p>
										</div>
									</Link>
									
								</li>								
								
							))}	
							</ul>
						</li>
						<li>
							<ul class="lst8">
								<li><p>총 <span>4</span> 건</p></li>
								<li><button class="btn_ut3 modal_open2"><span class="sorting2">최신순</span></button></li>
							</ul>
							<ul class="lst3">
								<li>
									<a href="#!">
										<div class="box">
											<img src="/xpass/pjtCom/images/nft1.jpg" alt="" />
											<div class="btn_area">
												<button class="btn_ut2 active"><span class="sp1">like</span><span>37</span></button>
											</div>
										</div>
										<div>
											<p class="txt">뮤지컬 오페라의 유령 #1234</p>
											<p class="txt txt_1"><span>400</span> KLAY</p>
											<p class="txt1">(≈ <span>120,000</span>원)</p>
										</div>
									</a>
								</li>
								<li>
									<a href="#!">
										<div class="box">
											<img src="/xpass/pjtCom/images/nft2.jpg" alt="" />
											<div class="btn_area">
												<button class="btn_ut2"><span class="sp1">like</span><span>374</span></button>
											</div>
										</div>
										<div>
											<p class="txt">SCHOOL OF ROCK</p>
											<p class="txt txt_1"><span>400</span> KLAY</p>
											<p class="txt1">(≈ <span>120,000</span>원)</p>
										</div>
									</a>
								</li>
								<li>
									<a href="#!">
										<div class="box">
											<img src="/xpass/pjtCom/images/nft6.jpg" alt="" />
											<div class="btn_area">
												<button class="btn_ut2 active"><span class="sp1">like</span><span>1</span></button>
											</div>
										</div>
										<div>
											<p class="txt">NEXT TO NORMAL</p>
											<p class="txt txt_1"><span>400</span> KLAY</p>
											<p class="txt1">(≈ <span>120,000</span>원)</p>
										</div>
									</a>
								</li>
								<li>
									<a href="#!">
										<div class="box">
											<img src="/xpass/pjtCom/images/nft5.jpg" alt="" />
											<div class="btn_area">
												<button class="btn_ut2"><span class="sp1">like</span><span>37</span></button>
											</div>
										</div>
										<div>
											<p class="txt">이은결 MAGIC & ILLUSION</p>
											<p class="txt txt_1"><span>400</span> KLAY</p>
											<p class="txt1">(≈ <span>120,000</span>원)</p>
										</div>
									</a>
								</li>						
							</ul>
						</li>
					</ul>
				</div>
			</section>
			
			<section class="area3">
	<div>
		<ul class="lst4">
			<li><a class="home" href="/">HOME</a></li>
			<li><a class="tick" href="/ticket">TICKET</a></li>
			<li><a class="mark" href="/market">MARKET</a></li>
			<li><a class="mypa" href="/mypage">MY PAGE</a></li>
		</ul>
	</div>
</section>	
			
		</div>
	</div>

	<div class="modal_bg modal_close">		
	</div>

	<div class="modal_box">
		<div class="modal modal1 sort1">
			<ul class="lst10">
				<li><button class="active">최신순</button></li>
				<li><button>가격순</button></li>
				<li><button>관심순</button></li>
			</ul>
			<div class="btn_area2"><button class="btn_ut4 modal_close">Cancel</button></div>
		</div>
	</div>

	<div class="modal_box">
		<div class="modal modal1 sort2">
			<ul class="lst10">
				<li><button class="active">최신순</button></li>
				<li><button>가격순</button></li>
				<li><button>관심순</button></li>
			</ul>
			<div class="btn_area2"><button class="btn_ut4 modal_close">Cancel</button></div>
		</div>
	</div>
    </React.Fragment>
        
        
    )
}

