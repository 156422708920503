import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//import Modal from "../components/Modal";
//import QRcode from "../components/QRcode";
//import { getBalance, fetchCardsOf, getSaleStatus, fetchCardsOf2, getBalanceXTC } from "../api/UseCaver";
//import * as KlipAPI from "../api/UseKlip";
import { DEFAULT_ADDRESS } from "../constants/constants.klip";

export default function MyPage () {
  //const [nfts, setNfts] = useState([]); // {id: '101', uri: ''}
	const myAddress = window.sessionStorage.getItem('address') || DEFAULT_ADDRESS;
	const myBalance = window.sessionStorage.getItem('balance') || 0;

  //const qrvalue = DEFAULT_QR_CODE;
  //const [showModal, setShowModal] = useState(false);

  //const [nfts2, setNfts2] = useState([]);
	const xtcBalance = window.sessionStorage.getItem('xtcbalance');
/*
  const [modalProps, setModalProps] = useState({
		title: "MODAL",
		onConfirm: () => {},
	});
*/
	//공연중일 경우 판매가격에 제한을 주기위한 체크함수
	//const [checkLitmitPrice, setCheckLitmitPrice] = useState(false);
	//let checkBeforeDate = false;

    // 공연중인 티켓일 경우 2차 판매금액의 최고금액을 제한한다.
    /*
	const getCheckBeforeDate = (_pDate) => {
		//const regex = /[^0-9]/g;
		_pDate = _pDate.replace("년", "-");
		_pDate = _pDate.replace("월", "-");
		_pDate = _pDate.replace("일", "");
		_pDate = _pDate.replace(/\s+/g, "");
		const arrDate = _pDate.split("-");
	
		const pDate = new Date(`${arrDate[0]}-${arrDate[1] >= 10 ? arrDate[1] : '0' + arrDate[1]}-${arrDate[2] >= 10 ? arrDate[2] : '0' + arrDate[2]}`);
		//console.log(pDate);
		const tDate = new Date();
		if(pDate.getTime() > tDate.getTime()) {
		
			return true;
		}
		return false;
	}
  */
	//종가 클레이 가격
	const [klayPrice, setKlayPrice] = useState('0');	
	//판매승인 확인
	//const [saleStatus, setSaleStatus] = useState(false);
//	console.log(saleStatus);
	//const account = myAddress;
 
	/////////////////////////////////////////////////////////////////////

  //클립 지갑연동
  /*
	const getUserData = () => {
		setModalProps({
			title: "Klip 지갑을 연동하시겠습니까?",
			onConfirm: () => {
				KlipAPI.getAddress(setQrvalue, async (address) => {
					window.sessionStorage.setItem('address', address);
					setMyAddress(address);
					const _balance = await getBalance(address);
					window.sessionStorage.setItem('balance', _balance);
					setMyBalance(_balance);
					const _balance_xtc = await getBalanceXTC(address);
					window.sessionStorage.setItem('xtcbalance', _balance_xtc);
					setXtcBalance(_balance_xtc);
					//navigate('/goods');
				});
			},
		});
		setShowModal(true);
	}
*/
  useEffect(() => {

		//종가 클레이튼 가격 가져요기
		const fetchKlayPrice = async () => {
			if (myAddress === DEFAULT_ADDRESS) {
				alert("NO ADDRESS");
				return;
			}

			try {
				const URL = "https://gametok.co.kr/api/klay_info.json";

				const response = await fetch(URL);
      	const result = await response.json();
				console.log(result.tickers[0].last);
				setKlayPrice(result.tickers[0].last);
			}catch(error){
				console.error(error);
			}
		
		}

		if (myAddress !== DEFAULT_ADDRESS) {
			//fetchMyNFTs();
			//fetchMyNFTs2();
			fetchKlayPrice();
		}
		
	}, [myAddress]);
///////////////////////////////////////////////////////////////////////////////////////////
// Render
//////////////////////////////////////////////////////////////////////////////////////////
if(myAddress !== DEFAULT_ADDRESS) {
  return(
    <div id="container">
		<div id="content">
			<section class="area8">
				<div class="tle"><h2><span>마</span>이 페이지</h2></div>
				<div class="area4">
					<ul class="lst19">
						<li><div class="imgbox"><img src="/xpass/pjtCom/images/nft6.jpg" alt="" /></div></li>
						<li><p class="txt1">Markers0909</p></li>
						<li><a href="#!">edit</a></li>
					</ul>
					<div class="area15">
						<div class="area17">
							<ul class="lst20">
								<li><p>KLIP 주소</p><p class="txt3 klip"><button class="btn_ut6"><span>{myAddress}</span><span class="sp1">copy</span></button></p></li>
								<li><p>잔액</p><p class="txt klay"><span><strong>{myBalance}</strong> KLAY</span><span class="txt2">(≈ <span>{(Math.round(klayPrice) * Math.round(myBalance)).toLocaleString('ko-KR')}</span>원)</span></p></li>
								<li><p>포인트</p><p class="txt xtc"><strong>{xtcBalance}</strong> XTC</p></li>
							</ul>
						</div>
						<div>
							<ul class="lst21">
								<li><Link to="/mypage_nft">MY NFT</Link></li>
								<li><a href="#!">거래내역</a></li>
								<li><a href="#!">출석체크</a></li>
							</ul>
						</div>
						<div class="area18 v2">

						</div>
					</div>
				</div>
			</section>
			<section class="area3">
	<div>
  <ul class="lst4">
			<li><Link to="/" class="home">HOME</Link></li>
			<li><Link to="/ticket" class="tick">TICKET</Link></li>
			<li><Link to="/market" class="mark">MARKET</Link></li>
			<li><Link to="/mypage" class="mypa">MY PAGE</Link></li>
		</ul>
	</div>
</section>	
		</div>
	</div>
  );
}
else {
  window.location.replace('/login');
	return;
}
  
} 
