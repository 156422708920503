import React from "react";
//import "../styles/xp.css";
//import "../styles/swiper-bundle.min.css";
// Ctrl + Shift + L (선택 한번에 수정)
export default function Header() {
	return (
<header id="header">
	<div class="menuArea">
		<h1><a href="#!" class="logo">X-PASS</a></h1>
		<button class="gnv_open">main menu</button>		
		<div class="gnv">
			<ul class="mainmenu">
				<li><a href="#!" class="serive">서비스</a>
					<ul>
						<li><a href="#!">X TICKET 예매</a></li>
						<li><a href="#!">마켓</a></li>
						<li><a href="#!">이벤트</a></li>
					</ul>
				</li>
				<li><a href="#!" class="mypage">마이페이지</a></li>
				<li><a href="#!" class="notice">공지사항</a></li>
				<li><a href="#!" class="customer">고객센터</a></li>
				<li><a href="#!" class="policy">약관 및 정책</a>
					<ul>
						<li><a href="#!">수수료 정책 안내</a></li>
					</ul>
				</li>
			</ul>
			<div class="social">
				<ul>
					<li><a href="#!" class="ex">ex</a></li>
					<li><a href="#!" class="disc">discord</a></li>
					<li><a href="#!" class="tele">telegram</a></li>
					<li><a href="#!" class="medi">medium</a></li>
					<li><a href="#!" class="inst">instagram</a></li>
				</ul>
			</div>
			<div class="langmenu">
				<ul>
					<li class=""><a href="#!" onClick={false} target="_self">EN</a></li>
					<li class="active"><a href="#!" onClick={false} target="_self">KO</a></li>
				</ul>
			</div>
		</div>
	</div>
</header>
  );
}