import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//import Modal from "../components/Modal";
//import QRcode from "../components/QRcode";
import { fetchCardsOf, getSaleStatus, fetchCardsOf2 } from "../api/UseCaver";
//import * as KlipAPI from "../api/UseKlip";
import { DEFAULT_ADDRESS } from "../constants/constants.klip";

export default function MyPageNFT () {
  const [nfts, setNfts] = useState([]); // {id: '101', uri: ''}
  const myAddress = window.sessionStorage.getItem('address') || DEFAULT_ADDRESS;
	//const myBalance = window.sessionStorage.getItem('balance');

  //const qrvalue = DEFAULT_QR_CODE;
  //const [showModal, setShowModal] = useState(false);

  const [nfts2, setNfts2] = useState([]);
  const navigate = useNavigate();
	//const xtcBalance = window.sessionStorage.getItem('xtcbalance');
/*
  const [modalProps, setModalProps] = useState({
		title: "MODAL",
		onConfirm: () => {},
	});
*/
	//공연중일 경우 판매가격에 제한을 주기위한 체크함수
	//const [checkLitmitPrice, setCheckLitmitPrice] = useState(false);
	//let checkBeforeDate = false;

    // 공연중인 티켓일 경우 2차 판매금액의 최고금액을 제한한다. 공연이 끝난 NFT는 굿즈탭으로 이동
    
	const getCheckBeforeDate = (_pDate) => {
		//const regex = /[^0-9]/g;
		_pDate = _pDate.replace("년", "-");
		_pDate = _pDate.replace("월", "-");
		_pDate = _pDate.replace("일", "");
		_pDate = _pDate.replace(/\s+/g, "");
		const arrDate = _pDate.split("-");
	
		const pDate = new Date(`${arrDate[0]}-${arrDate[1] >= 10 ? arrDate[1] : '0' + arrDate[1]}-${arrDate[2] >= 10 ? arrDate[2] : '0' + arrDate[2]}`);
		//console.log(pDate);
		const tDate = new Date();
		if(pDate.getTime() > tDate.getTime()) {
		
			return true;
		}
		return false;
	}
  
	//종가 클레이 가격
	const [klayPrice, setKlayPrice] = useState('0');	
	console.log(klayPrice)
	//판매승인 확인
	const [saleStatus, setSaleStatus] = useState(false);
	console.log(saleStatus);
	//const account = myAddress;
 // NFT 총보유수(공연전티켓), 보유/판매중, GOODS 총보유수(공연후티켓), 보유/판매중
 const [nftCnt, setNftCnt] = useState('0');
	/////////////////////////////////////////////////////////////////////
  useEffect(() => {
		if(myAddress === DEFAULT_ADDRESS) {
			window.location.replace('/login');
			return;
		}
    const fetchMyNFTs = async () => {
			if (myAddress === DEFAULT_ADDRESS) {
				alert("NO ADDRESS");
				return;
			}
			const _nfts = await fetchCardsOf(myAddress);
				setNfts(_nfts);
				setNftCnt(_nfts.length);
		
			const _saleStatus = await getSaleStatus(myAddress);
			setSaleStatus(_saleStatus);
		}
		//other NFT contract
		const fetchMyNFTs2 = async () => {
			if (myAddress === DEFAULT_ADDRESS) {
				alert("NO ADDRESS");
				return;
			}
			const _nfts = await fetchCardsOf2(myAddress);
				setNfts2(_nfts);

		}

		//종가 클레이튼 가격 가져요기
		const fetchKlayPrice = async () => {
			if (myAddress === DEFAULT_ADDRESS) {
				alert("NO ADDRESS");
				return;
			}

			try {
				const URL = "https://gametok.co.kr/api/klay_info.json";

				const response = await fetch(URL);
      	const result = await response.json();
				console.log(result.tickers[0].last);
				setKlayPrice(result.tickers[0].last);
			}catch(error){
				console.error(error);
			}
		
		}

		if (myAddress !== DEFAULT_ADDRESS) {
			fetchMyNFTs();
			fetchMyNFTs2();
			fetchKlayPrice();
		}
		
	}, [myAddress]);
///////////////////////////////////////////////////////////////////////////////////////////
// Render
//////////////////////////////////////////////////////////////////////////////////////////
return(
	<>
	<div id="container">
		<div id="content">
			<section class="area7 v2 v3">
				<ul class="lst11">
					<li><a class="btn_ut" href="#!" onClick={() => navigate(-1)}>back</a></li>
					<li><h2>MY NFT</h2></li>
				</ul>
			</section>
			<section class="area11">
				<div class="area10 v2 taparea" now="1">
					<div>
						<ul class="lst7">
							<li><button class="tap active" tap="1">티켓 NFT <span>(<span>{nftCnt}</span>)</span></button></li>
							<li><button class="tap" tap="2">굿즈 NFT <span>(<span>4</span>)</span></button></li>
						</ul>
					</div>
					<ul class="lst9">
						<li class="taparea_1" now="1">
							<ul class="lst25">
								<li><button class="tap_1 active" tap="1">보유 중 <span>(<span>2</span>)</span></button></li>
								<li><button class="tap_1" tap="2">판매 중 <span>(<span>4</span>)</span></button></li>
							</ul>
							<ul class="lst9">
								<li>
									<ul class="lst2">
										{nfts.map((nft) => (
										<li>
										<Link to={`/mypage_nft_detail/${nft.id}`}>
											<div class="box">
												<img src={nft.uri} alt="" />
												<div class="btn_area">
													<button class="btn_ut2 active"><span class="sp1">like</span><span>237</span></button>
												</div>												
												<div class="btn_area5 active">
													<div class="btn_ut9"><span class="sp1">hidden</span></div>
												</div>													
											</div>
											<div class="box1">
												<p class="txt">뮤지컬 오페라의 유령</p>
												<p>라움아트센터 (마제스틱 볼룸)</p>
												<p>2024.05.08 (수) 19:30</p>
												<p>S석 2층 B구역 3열</p>
												<p class="txt txt_1"><span>400</span> KLAY</p>
												<p class="txt1">(≈ <span>120,000</span>원)</p>
											</div>												
										</Link>
										<div class="btn_area4"><button class="btn_ut8 modal_open">Detail</button></div>										
									</li>
										))}
										
									</ul>
								</li>
								<li>
									<ul class="lst2">							
										<li>
											<a href="#!">
												<div class="box">
													<img src="/xpass/pjtCom/images/nft6.jpg" alt="" />
													<div class="btn_area">
														<button class="btn_ut2 active"><span class="sp1">like</span><span>3</span></button>
													</div>												
													<div class="btn_area5">
														<div class="btn_ut9"><span class="sp1">hidden</span></div>
													</div>													
												</div>
												<div class="box1">
													<p class="txt">뮤지컬 오페라의 유령</p>
													<p>라움아트센터 (마제스틱 볼룸)</p>
													<p>2024.05.08 (수) 19:30</p>
													<p>S석 2층 B구역 3열</p>
													<p class="txt txt_1"><span>400</span> KLAY</p>
													<p class="txt1">(≈ <span>120,000</span>원)</p>
												</div>
											</a>
											<div class="btn_area4"><button class="btn_ut8 modal_open">Detail</button></div>	
										</li>
										<li>
											<a href="#!">
												<div class="box">
													<img src="/xpass/pjtCom/images/nft5.jpg" alt="" />
													<div class="btn_area">
														<button class="btn_ut2"><span class="sp1">like</span><span>37</span></button>
													</div>												
													<div class="btn_area5 active">
														<div class="btn_ut9"><span class="sp1">hidden</span></div>
													</div>													
												</div>
												<div class="box1">
													<p class="txt">제16회 서울재즈페스티벌 2024</p>
													<p>라움아트센터 (마제스틱 볼룸)</p>
													<p>2024.05.08 (수) 19:30</p>
													<p>S석 2층 B구역 3열</p>
													<p class="txt txt_1"><span>400</span> KLAY</p>
													<p class="txt1">(≈ <span>120,000</span>원)</p>
												</div>
											</a>
											<div class="btn_area4"><button class="btn_ut8 modal_open">Detail</button></div>	
										</li>
									</ul>
								</li>
							</ul>

						</li>
						<li class="taparea_2" now="1">
							<ul class="lst25">
								<li><button class="tap_2 active" tap="1">보유 중 <span>(<span>2</span>)</span></button></li>
								<li><button class="tap_2" tap="2">판매 중 <span>(<span>4</span>)</span></button></li>
							</ul>
							<ul class="lst9">
								<li>
									<ul class="lst3">
										<li>
											<a href="#!">
												<div class="box">
													<img src="/xpass/pjtCom/images/nft1.jpg" alt="" />
													<div class="btn_area">
														<button class="btn_ut2 active"><span class="sp1">like</span><span>37</span></button>
													</div>												
													<div class="btn_area5 active">
														<div class="btn_ut9"><span class="sp1">hidden</span></div>
													</div>													
												</div>
												<div>
													<p class="txt">뮤지컬 오페라의 유령 #1234</p>
													<p class="txt txt_1"><span>400</span> KLAY</p>
													<p class="txt1">(≈ <span>120,000</span>원)</p>
												</div>
											</a>
											<div class="btn_area4"><button class="btn_ut8 modal_open">Detail</button></div>
										</li>
										<li>
											<a href="#!">
												<div class="box">
													<img src="/xpass/pjtCom/images/nft2.jpg" alt="" />
													<div class="btn_area">
														<button class="btn_ut2"><span class="sp1">like</span><span>374</span></button>
													</div>												
													<div class="btn_area5">
														<div class="btn_ut9"><span class="sp1">hidden</span></div>
													</div>													
												</div>
												<div>
													<p class="txt">SCHOOL OF ROCK</p>
													<p class="txt txt_1"><span>400</span> KLAY</p>
													<p class="txt1">(≈ <span>120,000</span>원)</p>
												</div>
											</a>
											<div class="btn_area4"><button class="btn_ut8 modal_open">Detail</button></div>
										</li>
										<li>
											<a href="#!">
												<div class="box">
													<img src="/xpass/pjtCom/images/nft6.jpg" alt="" />
													<div class="btn_area">
														<button class="btn_ut2 active"><span class="sp1">like</span><span>1</span></button>
													</div>												
													<div class="btn_area5 active">
														<div class="btn_ut9"><span class="sp1">hidden</span></div>
													</div>													
												</div>
												<div>
													<p class="txt">NEXT TO NORMAL</p>
													<p class="txt txt_1"><span>400</span> KLAY</p>
													<p class="txt1">(≈ <span>120,000</span>원)</p>
												</div>
											</a>
											<div class="btn_area4"><button class="btn_ut8 modal_open">Detail</button></div>										
										</li>
										<li>
											<a href="#!">
												<div class="box">
													<img src="/xpass/pjtCom/images/nft5.jpg" alt="" />
													<div class="btn_area">
														<button class="btn_ut2"><span class="sp1">like</span><span>37</span></button>
													</div>												
													<div class="btn_area5">
														<div class="btn_ut9"><span class="sp1">hidden</span></div>
													</div>													
												</div>
												<div>
													<p class="txt">이은결 MAGIC & ILLUSION</p>
													<p class="txt txt_1"><span>400</span> KLAY</p>
													<p class="txt1">(≈ <span>120,000</span>원)</p>
												</div>
											</a>
											<div class="btn_area4"><button class="btn_ut8 modal_open">Detail</button></div>	
										</li>						
									</ul>
								</li>
								<li>
									<ul class="lst3">								
										<li>
											<a href="#!">
												<div class="box">
													<img src="/xpass/pjtCom/images/nft6.jpg" alt="" />
													<div class="btn_area">
														<button class="btn_ut2 active"><span class="sp1">like</span><span>1</span></button>
													</div>
													<div class="btn_area5 active">
														<div class="btn_ut9"><span class="sp1">hidden</span></div>
													</div>	
												</div>
												<div>
													<p class="txt">NEXT TO NORMAL</p>
													<p class="txt txt_1"><span>400</span> KLAY</p>
													<p class="txt1">(≈ <span>120,000</span>원)</p>
												</div>
											</a>
											<div class="btn_area4"><button class="btn_ut8 modal_open">Detail</button></div>	
										</li>
										<li>
											<a href="#!">
												<div class="box">
													<img src="/xpass/pjtCom/images/nft5.jpg" alt="" />
													<div class="btn_area">
														<button class="btn_ut2"><span class="sp1">like</span><span>37</span></button>
													</div>
													<div class="btn_area5">
														<div class="btn_ut9"><span class="sp1">hidden</span></div>
													</div>	
												</div>
												<div>
													<p class="txt">이은결 MAGIC & ILLUSION</p>
													<p class="txt txt_1"><span>400</span> KLAY</p>
													<p class="txt1">(≈ <span>120,000</span>원)</p>
												</div>
											</a>
											<div class="btn_area4"><button class="btn_ut8 modal_open">Detail</button></div>	
										</li>						
									</ul>
								</li>
							</ul>
						</li>
					</ul>
					</div>
			</section>	
		</div>
	</div>

	<div class="modal_bg modal_close">
	</div>

	<div class="modal_box">
		<div class="modal modal1 detail1">
			<ul class="lst10 v2">
				<li><button>티켓확인</button></li>
				<li><button>판매하기</button></li>

				<li><button>전송하기</button></li>
				<li><button>숨기기</button></li>

			</ul>
			<div class="btn_area2"><button class="btn_ut4 modal_close">Cancel</button></div>
		</div>
	</div>
	</>
		
);

}