import React, { useState } from "react";
import { Link } from "react-router-dom";
import QRcode from "../components/QRcode";
import { getBalance, getBalanceXTC } from "../api/UseCaver";
import * as KlipAPI from "../api/UseKlip";
import { DEFAULT_QR_CODE, DEFAULT_ADDRESS } from "../constants/constants.klip";
// Ctrl + Shift + L (선택 한번에 수정)

export default function Login() {
  const [myAddress, setMyAddress] = useState(window.sessionStorage.getItem('address') || DEFAULT_ADDRESS);
  const [myBalance, setMyBalance] = useState(window.sessionStorage.getItem('balance') || '0');
  const [xtcBalance, setXtcBalance] = useState(window.sessionStorage.getItem('xtcbalance') || '0');

	const [qrvalue, setQrvalue] = useState(DEFAULT_QR_CODE);

  //종가 클레이 가격
	//const [klayPrice, setKlayPrice] = useState('0');	
	//판매승인 확인
	//const [saleStatus, setSaleStatus] = useState(false);
	//console.log(saleStatus);

  const getUserData = () => {
    if(myAddress !== DEFAULT_ADDRESS) {
      window.location.replace('/mypage');
      //const navigate = useNavigate()
      //navigate('/mypage', {replace: true})  
      return;
    }
    KlipAPI.getAddress(setQrvalue, async (address) => {
      window.sessionStorage.setItem('address', address);
      setMyAddress(address);
      console.log(myAddress);
      console.log(qrvalue);
      const _balance = await getBalance(address);
      window.sessionStorage.setItem('balance', _balance);
      setMyBalance(_balance);
      console.log(myBalance);
      const _balance_xtc = await getBalanceXTC(address);
      window.sessionStorage.setItem('xtcbalance', _balance_xtc);
      setXtcBalance(_balance_xtc);
      console.log(xtcBalance);
      //navigate('/goods');
			window.location.replace('/mypage');
			return;
    })
  }

	return (
    <div id="container">
		<div id="content">
			<div class="area7">
				<a class="btn_ut" href="#!">back</a>
			</div>
			<section class="area5">				
				<div class="area6">					
					<div>
						<div class="tle1">
							<h2>로그인이 필요합니다.</h2>
						</div>
						<div class="txtbox">
							<p>서비스를 계속 이용하려면<br/> Klip 로그인이 필요합니다.</p>
						</div>
					</div>
					<div class="area1">
						<a class="btn" href="#!" onClick={getUserData}><span>Klip 로그인</span></a>
					</div>
          {qrvalue !== "DEFAULT" ? <QRcode value={qrvalue} /> : null}
					<div class="txtbox2">
						<p>최초 지갑 연동하면 + 3 <span>XTC</span> 적립!</p>
					</div>
				</div>
			</section>	
			<section class="area3">
	<div>
    <ul class="lst4">
			<li><Link to="/" class="home">HOME</Link></li>
			<li><Link to="/ticket" class="tick">TICKET</Link></li>
			<li><Link to="/market" class="mark">MARKET</Link></li>
			<li><Link to="/mypage" class="mypa">MY PAGE</Link></li>
		</ul>
	</div>
</section>	
		</div>
	</div>
  
  );
}