/*
export const NFT_CONTRACT_ADDRESS = "0x3c21785793eA7CeA01e680430da45eBd8c4DA08d" // modifiedKIP17Token 컨트랙트 배포 주소
export const MARKET_CONTRACT_ADDRESS = "0xAE4F4D50e0d5383Cd25214c610366241d1324820" // NFTMarket 컨트랙트 배포 주소
export const ACCESS_KEY_ID = "KASK33VHU27V7IBNB1ZN6LTL" // KAS API ACCESS_KEY_ID
export const SECRET_ACCESS_KEY = "rEA3A2q1AV79TxlbVxGzUsiVS2mVtIIPCBUaagw6" // KAS API SECRET_ACCESS_KEY
export const CHAIN_ID = "1001" 
*/
//
export const NFT_CONTRACT_ADDRESS = "0x050a62adb73b58af5357df09434d9f8e76130d10" //"0x596a466100afdf4607d1c6896649e18d04e90b58" // modifiedKIP17Token 컨트랙트 배포 주소
export const MARKET_CONTRACT_ADDRESS = "0x3227741cd1682057c811fa66307174f4af1d727f" //"0x3c21785793eA7CeA01e680430da45eBd8c4DA08d" // NFTMarket 컨트랙트 배포 주소
export const ACCESS_KEY_ID = "KASK33VHU27V7IBNB1ZN6LTL" // KAS API ACCESS_KEY_ID
export const SECRET_ACCESS_KEY = "rEA3A2q1AV79TxlbVxGzUsiVS2mVtIIPCBUaagw6" // KAS API SECRET_ACCESS_KEY
export const CHAIN_ID =  "8217" 