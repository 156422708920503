import React, { useState, useEffect } from "react";
//import { Card, Row, Col, Button, Toast, Alert } from "react-bootstrap";
//import Modal from "../components/Modal";
import QRcode from "../components/QRcode";
import { getBalance, getNftInfo, getBalanceXTC } from "../api/UseCaver";
import * as KlipAPI from "../api/UseKlip";
import { DEFAULT_QR_CODE, DEFAULT_ADDRESS } from "../constants/constants.klip";
//import LogoKlaytn from "../assets/logo_klaytn.png";
//import { useHistory, useParams } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';

export default function MarketDetail () {
    const { tid } = useParams();
    console.log(tid);
    const navigate = useNavigate();

	//const [nfts, setNfts] = useState([]); // {id: '101', uri: ''}
	const [myAddress, setMyAddress] = useState(window.sessionStorage.getItem('address') || DEFAULT_ADDRESS);
	const [myBalance, setMyBalance] = useState(window.sessionStorage.getItem('balance') || '0');
    const [xtcBalance, setXtcBalance] = useState(window.sessionStorage.getItem('xtcbalance') || '0');

    const [qrvalue, setQrvalue] = useState(DEFAULT_QR_CODE);

    //종가 클레이 가격
    const [klayPrice, setKlayPrice] = useState('0');

    //구매 이벤트
    const onClickBuyPriceExe = (tokenId, _sellPrice) => {
    //const sellPrice = (_sellPrice * 10000000000000000).toString(); // 0.01 klay(1klay = 10 ^ 18)
    const sellPrice = (_sellPrice / 100).toString();
    KlipAPI.buyXPassToken(tokenId, sellPrice, setQrvalue, (result) => {
      alert(JSON.stringify(result));
      window.location.reload();
      navigate('/mypage');
    });
  };
//판매가격
const [sellPrice, setSellPrice] = useState("0");
//최초판매가격
const [ticketPrice, setTicketPrice] = useState("0");
//fetch data
const [imageUri, setImageUri] = useState("");
const [playDate, setPlayDate] = useState("");
const [playName, setPlayName] = useState("");

//check balance only one
const [checkBalnce, setCheckBalance] = useState(false);

  //klip login
  const getUserData = () => {

    KlipAPI.getAddress(setQrvalue, async (address) => {
      window.sessionStorage.setItem('address', address);
      setMyAddress(address);
      console.log(myAddress);
      console.log(qrvalue);
      const _balance = await getBalance(address);
      window.sessionStorage.setItem('balance', _balance);
      setMyBalance(_balance);
      console.log(myBalance);
      const _balance_xtc = await getBalanceXTC(address);
      window.sessionStorage.setItem('xtcbalance', _balance_xtc);
      setXtcBalance(_balance_xtc);
      console.log(xtcBalance);
      //navigate('/goods');
			window.location.reload();
			return;
    })
  }
  const onClickBuyPrice = (_sellPrice) => {
  
    //alert('결제금액 : ' + _sellPrice + '');
    //return;
    //setModalProps({
    //  title: _sellPrice + " klay에 구매하시겠습니까?",
    //  onConfirm: () => {
        onClickBuyPriceExe(tid, _sellPrice);
    //  },
    //})
    //setShowModal(true);
  
  }

  useEffect(() => {

    const setNftMetaData = async (_nfts) =>{
        _nfts.map((nft) => {
          setImageUri(nft.uri);
          setPlayName(nft.name);
          setSellPrice(nft.sellPrice);
    
          nft.attributes.map((attr) => {
            //공연일 이전인지 체크
            if(attr.trait_type === "공연일시"){
              //console.log(attr.value);
              //checkBeforeDate = getCheckBeforeDate(attr.value);
              setPlayDate(attr.value);
              //attr.value = new Date();
            }
            //공연중일때 판매가격 제한
            if(attr.trait_type === "티켓가격"){
              //attr.value = Number(attr.value) * 2;
              setTicketPrice(attr.value);
              //klay 현재가격
      
              //최대가격이상일 경우 리턴
      
              
            }
            
          })
        })
      
    }

    const getBalances = async () => {
        const address = myAddress;
        const _balance = await getBalance(address);
      window.sessionStorage.setItem('balance', _balance);
      setMyBalance(_balance);
      console.log(myBalance);
      const _balance_xtc = await getBalanceXTC(address);
      window.sessionStorage.setItem('xtcbalance', _balance_xtc);
      setXtcBalance(_balance_xtc);
      console.log(xtcBalance);
      //navigate('/goods');
			window.location.reload();
			return;
    }

    const fetchMyNFTs = async () => {
     /*
        if (myAddress === DEFAULT_ADDRESS) {
        alert("NO ADDRESS");
        return;
      }
      */
      const _nfts = await getNftInfo(tid);
        //setNfts(_nfts);
        setNftMetaData(_nfts);
      //await getNftInfo(82211);
      //const _saleStatus = await getSaleStatus(myAddress);
      //setSaleStatus(_saleStatus);
    }
  
    //종가 클레이튼 가격 가져요기
    const fetchKlayPrice = async () => {
    /*
      if (myAddress === DEFAULT_ADDRESS) {
        alert("NO ADDRESS");
        return;
      }
  */
      try {
        const URL = "https://gametok.co.kr/api/klay_info.json";
  
        const response = await fetch(URL);
        const result = await response.json();
        console.log(result.tickers[0].last);
        setKlayPrice(result.tickers[0].last);
      }catch(error){
        console.error(error);
      }
      
      //setKlayPrice('258');
    }
  /*
    //판매가격 가져오기
    const getSellPriceByTid = async () => {
      if (myAddress === DEFAULT_ADDRESS) {
        alert("NO ADDRESS");
        return;
      }
  
      try {
        const _sellPrice = await getSellPrice(tid);
        console.log(_sellPrice);
        setSellPrice(_sellPrice);
  
      } catch(error) {
        console.log(error);
      }
    }
  */
    //if (myAddress !== DEFAULT_ADDRESS) {
      fetchMyNFTs();
      fetchKlayPrice();
      //getSellPriceByTid();
      if(myAddress !== DEFAULT_ADDRESS && myBalance === "0" && !checkBalnce) {
        getBalances();
        setCheckBalance(true);
      }
    //}
    
    
  }, [myAddress, tid]);
//////////////////////////////////////////////////////////////////////////////
    return (
        <div id="container">
		<div id="content">
			<section class="area7 v2 v3">
				<ul class="lst11">
					<li><a class="btn_ut" href="#!" onClick={() => navigate(-1)}>back</a></li>
					<li><h2>구매하기</h2></li>
				</ul>
			</section>
			<section class="area11">
                <ul class="lst2 v2">
					<li>
						<div class="box">
							<img src={imageUri} alt="" />
						</div>
						<div class="box1">
							<p class="txt">{playName}</p>
							<p>라움아트센터 (마제스틱 볼룸)</p>
							<p>{playDate}</p>
							<p>S석 2층 B구역 3열</p>
							<p class="txt txt_1"><span>40</span> KLAY</p>
							<p class="txt1">(≈ <span>{klayPrice}</span>원)</p>
						</div>
					</li>
				</ul>
				<div class="area12">
					<div class="tle_area v2"><h4 class="tle3"><span>결</span>제 정보</h4><p><strong>KLAY</strong>로 결제</p></div>
					<div class="area21 v2">						
						<ul class="lst13">
							<li><p>보유잔고</p></li>
							<li>                               
                                {myAddress !== DEFAULT_ADDRESS ? (
								<p class="txt v3"><span>{myBalance}</span> KLAY</p>
                                ):(
                                    <div class="area1">
                                        {qrvalue !== "DEFAULT" ? <QRcode value={qrvalue} /> : null}
						                <a class="btn" href="#!" onClick={getUserData}><span>Klip 로그인</span></a>
					                </div>
                                )}
							</li>						
						</ul>
						<ul class="lst13">
							<li><p>결제금액</p></li>
							<li>
								<p class="txt"><span>{sellPrice}</span> KLAY</p>
							</li>						
						</ul>
					</div>					
					<div class="area16">
						<div class="tle_area"><h4 class="tle3"><span>계</span>약조건 확인 및 동의</h4></div>
						<div class="area18">
							<ul class="lst17">
								<li>판매 대상은 디지털 창작물의 소유권이며, 구체적인 판매조건은 양수도계약서에 따릅니다.</li>
								<li>해당 디지털 창작물 판매는 개인적인 거래여야하며, 사업적, 영업적 판매에 해당해서는 안됩니다.</li>
								<li>거래가 체결되면, 디지털 창작물의 소유권을 더 이상 행사할 수 없으므로 기존 내려받은 파일은 삭제하고, 게시, 전시 또는 공연을 중단하여야 합니다.</li>
								<li>NFT 보유자는 NFT와 연계된 “디지털 저작물“을 비상업적인 용도로 사용(개인 SNS 내 업로드하는 행위 및 디지털 저작물 원본 그대로 개인적 용도로 사용하는 행위)할 수 있는 권리와 NFT 입출금, 거래 등이 지원되는 경우 지원되는 방식에 따라 NFT를 타인에게 이전할 수 있는 권리를 갖습니다.</li>
								<li>거래 체결 금액에서 중개수수료 및 크리에이터 보상금액을 제외한 나머지 금액을 KLAY로 수령하게 됩니다.</li>	
							</ul>																	
						</div>
						<div class="btn_area6"><button class="btn v5">양수도계약 조건 확인하고 동의하기</button></div>
						<ul class="lst28">
							<li class="checkbox1">
								<input type="checkbox" id="agree1" />
								<label for="agree1">[필수] 양수도계약 조건 및 유의사항을 확인하였으며 동의함.</label>
							</li>
							<li class="checkbox1">
								<input type="checkbox" id="agree2" />
								<label for="agree2">[필수] 청약 철회 및 환불 불가에 대한 규정을 확인하였으며 동의함. <a href="#!">(내용보기)</a></label>
							</li>
							<li class="checkbox1">
								<input type="checkbox" id="agree3" />
								<label for="agree3">[필수] 양수도계약 체결을 위한 개인정보 제3자 제공에 동의함. <a href="#!">(내용보기)</a></label>
							</li>
						</ul>
						<div class="area18 v3">
							<p class="txt6">메타컬처스(주)는 통신판매중개자이며 통신판매의 당사자가 아닙니다. 작품(디지털 창작물) 및 거래(작품 가격 및 판매 수량, 작품 주문, 청약 철회, 배송, 교환, 반품 및 환불 등)에 관한 일체의 의무와 책임은 각 판매자에게 있으며, 메타컬처스(주)는 책임을 지지 않습니다. </p>
						</div>
					</div>
				</div>
			</section>
		
			<section class="area19">
				<div class="area20 v2">
					<ul class="btn_area3">
						<li><a class="btn v4" href="#!" onClick={() => navigate(-1)}>취소</a></li>						
						<li><a class="btn" href="#!" onClick={()=>{onClickBuyPrice(sellPrice)}}>결제하기</a></li>
					</ul>
				</div>
			</section>
		
		</div>
	</div>
    )
}